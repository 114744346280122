<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{user.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.users.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="user.email" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="user.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-card class="mt-3">
            <v-card-title>Intervals</v-card-title>
            <v-data-table
                :loading="intervalLoading"
                loading-text="Loading... Please wait"
                :headers="intervalHeaders"
                :items="intervals"
                :items-per-page="5"
                class="elevation-1"
            >
            </v-data-table>
        </v-card>
        <v-card class="mt-3">
            <v-card-title>Workouts</v-card-title>
            <v-data-table
                :loading="workoutLoading"
                loading-text="Loading... Please wait"
                :headers="workoutHeaders"
                :items="workouts"
                :items-per-page="5"
                class="elevation-1"
            >
            </v-data-table>
        </v-card>
        <v-card class="mt-3">
            <v-card-title>Xrays</v-card-title>
            <v-data-table
                :loading="xrayLoading"
                loading-text="Loading... Please wait"
                :headers="xrayHeaders"
                :items="xrays"
                :items-per-page="5"
                class="elevation-1"
            >
            </v-data-table>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import User from "../../../models/User";
    import Interval from "../../../models/Interval";
    import Workout from "../../../models/Workout";
    import Xray from "../../../models/Xray";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                user: {},
                intervals: [],
                intervalLoading: true,
                intervalHeaders: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Note', value: 'note' },
                    { text: 'Started at', value: 'started_at' },
                    { text: 'Ended at', value: 'ended_at' }
                ],
                workouts: [],
                workoutLoading: true,
                workoutHeaders: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Note', value: 'note' },
                    { text: 'Started at', value: 'started_at' },
                    { text: 'Ended at', value: 'ended_at' }
                ],
                xrays: [],
                xrayLoading: true,
                xrayHeaders: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Note', value: 'note' },
                ]
            }
        },
        async mounted() {
            const userId = this.$route.params.id
            this.user = await User.find(userId)
            // console.log('this.user.patient.id =', this.user.patient.id)
            this.intervals = await Interval.where('patient_id', this.user.patient.id).get()
            this.intervalLoading = false
            this.workouts = await Workout.where('patient_id', this.user.patient.id).get()
            this.workoutLoading = false
            this.xrays = await Xray.where('patient_id', this.user.patient.id).get()
            this.xrayLoading = false
        },
    }
</script>

<style scoped>

</style>
